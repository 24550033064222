import React from "react"
import Tiktok from "./tiktok.component"

const Social = () => {
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/royalshadesdxb"
        aria-label="Twitter"
      >
        <i className="fa fa-twitter" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/RoyalShades123"
        aria-label="Facebook"
      >
        <i className="fa fa-facebook" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/royalshadescurtains"
        aria-label="Instagram"
      >
        <i className="fa fa-instagram" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.pinterest.com/royalshadescurt"
        aria-label="Pinterest"
      >
        <i className="fa fa-pinterest" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/company/royalshadescurtainsllc/"
        aria-label="Linked In"
      >
        <i className="fa fa-linkedin" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCb2fvm-E0ujL7Fr5mPIiKuw"
        aria-label="Youtube"
      >
        <i className="fa fa-youtube" />
      </a>
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.tiktok.com/@royalshadescurtains?_t=8UKSk6bRG2E&_r=1"
        aria-label="Youtube"
      >
        <i>
          <Tiktok />
        </i>
      </a>
    </div>
  )
}

export default Social
