import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import AjaxLoader from "./ajaxloader.component"

const ContactForm = () => {
  const { addToast } = useToasts()

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onSubmit",
  })

  const [showSpinner, setShowSpinner] = useState(false)

  const handleOnSubmit = data => {
    setShowSpinner(true)
    const ausername = process.env.GATSBY_AUSERNAME
    const apassword = process.env.GATSBY_APASSWORD
    const url = process.env.GATSBY_URL

    data.to = "office@royalshadescurtains.com"
    data.from =
      "royalshadescurtains.com contact form<info@royalshadescurtains.com>"

    const token = Buffer.from(`${ausername}:${apassword}`, "utf8").toString(
      "base64"
    )
    axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then(r => {
        setShowSpinner(false)
        reset()
        addToast("Message sent successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(r => {
        setShowSpinner(false)
        console.log("Response", r)
        const response =
          r.response && r.response.data && r.response.data.error
            ? r.response.data.error
            : "Error in sending data"
        addToast(response, { appearance: "error", autoDismiss: true })
      })
  }

  return (
    <>
      <form
        id="contactForm"
        onSubmit={handleSubmit(handleOnSubmit)}
        className={`contact-form ${showSpinner ? "form-opacity" : ""}`}
        method="post"
        noValidate
      >
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="form-group margin_bottom_25">
              <div className="cols-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Your name"
                    ref={register({
                      required: "Name is required",
                    })}
                    aria-label="name"
                  />
                </div>
                <div className="help-block with-errors">
                  {errors.name && errors.name.message}
                </div>
              </div>
            </div>
            <div className="form-group margin_bottom_25">
              <div className="cols-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email address"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    aria-label="email"
                  />
                </div>
                <div className="help-block with-errors">
                  {errors.email && errors.email.message}
                </div>
              </div>
            </div>
            <div className="form-group margin_bottom_25">
              <div className="cols-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-phone fa-lg" aria-hidden="true" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="contactnumber"
                    name="contactnumber"
                    placeholder="Contact Number"
                    ref={register({
                      required: "Contact Number is required",
                      // pattern: {
                      //   value: /^(?:\+971|00971|0)?(?:50|51|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/i,
                      //   message: "Please enter valid contact number",
                      // },
                    })}
                    aria-label="contact number"
                  />
                </div>
                <div className="help-block with-errors">
                  {errors.contactnumber && errors.contactnumber.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group margin_bottom_25">
              <div className="cols-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-comment" aria-hidden="true" />
                  </span>
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows={5}
                    placeholder="Message"
                    ref={register({
                      required: "Message is required",
                    })}
                    aria-label="message"
                  />
                </div>
                <div className="help-block with-errors">
                  {errors.message && errors.message.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 mt-30">
            <div
              className="my-button"
              style={{ margin: "auto", display: "table" }}
            >
              <button className="btn-text" type="submit">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
      {showSpinner && <AjaxLoader />}
    </>
  )
}

export default ContactForm
