import React from "react"
import Social from "./social.component"
import NavigationLinks from "./navigationlinks.component"
import ContactForm from "./contactform.component"
import { ToastProvider } from "react-toast-notifications"

const Footer = () => {
  return (
    <footer className="footer pt-80 pt-xs-60">
      <div className="container">
        <div className="row footer-info mb-60">
          <div className="col-lg-3 col-md-4 col-xs-12 mb-sm-30 mb-xs-30">
            <h4 className="mb-30">Contact Us</h4>
            <address>
              <a href="https://maps.app.goo.gl/ZoxqrdTSccxkwbuq9" style={{color:"white"}}>
              <i className="fa fa-map-marker fa-icons"></i>
              Warehouse No. 5, 7, 8 &amp; 9, Ras Al Khor Ind Area 2, P.O.Box
              115717, Dubai UAE
              </a>
            </address>
            <ul className="link-small">
              <li>
                <a href="mailto:office@royalshadescurtains.com">
                  <i
                    className="fa fa-envelope fa-icons"
                    style={{ fontSize: "16px" }}
                  />
                  office@royalshadescurtains.com
                </a>
              </li>
              <li>
                <a href="tel:+971559908586">
                  <i
                    className="fa fa-phone fa-icons"
                    style={{ fontSize: "16px" }}
                  />
                  +971 55 9908586
                </a>
              </li>
            </ul>
            <div className="icons-hover-black">
              <Social />
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-xs-12 mb-sm-30 mb-xs-30">
            <h4 className="mb-30">Links</h4>
            <ul className="link blog-link">
              <NavigationLinks />
            </ul>
          </div>
          <div className="col-lg-7 col-md-12 col-xs-12 mb-sm-30 mb-xs-30">
            <h4 className="mb-30">Get In Touch</h4>
            <ToastProvider placement="top-right">
              <ContactForm />
            </ToastProvider>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <p>© {new Date().getFullYear()} Royal Shades Curtains LLC</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
