import React from "react"
import loaderimg from "../images/loader.gif"

const AjaxLoader = () => {
  return (
    <div className="ajax-loader">
      <img src={loaderimg} class="img-responsive" alt="loader" />
    </div>
  )
}

export default AjaxLoader
