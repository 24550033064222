import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Header from "./header.component"
import Footer from "./footer.component"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Header />
        {children}
        <Footer />
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 foot-mobile-contact">
          <div className="foot-contact-btns">
            <ul>
              <li className="phone-icon">
                <a href="tel:+971559908586" aria-label="Phone">
                  <i className="fa fa-phone" />
                </a>
              </li>
              <li className="whtsapp-icon">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="whatsapp://send?phone=+971559908586&amp;text="
                  aria-label="Whatsapp"
                >
                  <i className="fa fa-whatsapp" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
