import React, { useState, useEffect, useRef } from "react"
import logo from "../images/logo_royals.png"
import Social from "./social.component"
import NavigationLinks from "./navigationlinks.component"
import { Link } from "gatsby"

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)

  const onMenuClick = () => setOpenMenu(!openMenu)

  const [isSticky, setSticky] = useState(false)
  const topRef = useRef(null)

  const handleScroll = () => {
    if (topRef.current) {
      topRef.current.getBoundingClientRect().top + 300 <= 0
        ? setSticky(true)
        : setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <header>
      {/* <div className="top-part__block" ref={topRef}>
        <div className="search__box-block">
          <div className="container">
            <input
              type="text"
              id="search"
              className="input-sm form-full"
              placeholder="Search Now"
            />
            <a href="#!" className="search__close-btn">
              <i className="fa fa-times" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <p>
                <i className="fa fa-location-arrow" aria-hidden="true" />
                &nbsp;&nbsp;
                <span>
                  Warehouse No. 5, 7, 8 &amp; 9, Ras Al Khor Ind Area 2, P.O.Box
                  115717, Dubai UAE
                </span>
              </p>
            </div>
            <div className="col-sm-5">
              <div className="social-link__block text-right">
                <Social />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <div className="middel-part__block">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="Royal Shades Curtains LLC" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 hidden-sm-down">
                <div className="top-info__block text-right">
                  <ul>
                    <li>
                      <i className="fa fa-clock-o" />
                      <p>
                        Office Hours
                        <span>8:00 am to 6:00 pm</span>
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <p>
                        Call Us
                        <span>
                          <a href="tel:+971559908586">+971 55 9908586</a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <ul>
                      <li>
                        <i className="fa fa-envelope" aria-hidden="true" />
                        <p>
                          Mail Us
                          <span>
                            <a href="mailto:office@royalshadescurtains.com">
                              office@royalshadescurtains.com
                            </a>
                          </span>
                        </p>
                      </li>
                      <li>
                        <i className="fa fa-map-marker" />
                        <p>
                          Reach us
                          <span><a href="https://maps.app.goo.gl/ZoxqrdTSccxkwbuq9">Location</a></span>
                        </p>
                      </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`main_nav stricky-header__top navbar-toggleable-md ${
            isSticky ? "stricky" : ""
          }`}
        >
          <nav className="navbar navbar-default navbar-sticky bootsnav">
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div
                    className={`collapse navbar-collapse ${
                      openMenu ? "show" : ""
                    }`}
                    id="navbar-menu"
                  >
                    <ul className="nav navbar-nav mobile-menu">
                      <NavigationLinks />
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 d-flex justify-content-lg-end quote-sec">
                  <Link to="/contact">Consult Now !</Link>
                </div>
                <div className="col-sm-6">
                  <div className="navbar-header">
                    <div className="menu">
                      <button
                        type="button"
                        className="navbar-toggle hidden-lg-up"
                        onClick={onMenuClick}
                        aria-label="Menu"
                      >
                        <i className="fa fa-bars" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
