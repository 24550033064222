import React from "react"
import { Link } from "gatsby"

const NavigationLinks = () => {
  return (
    <>
      <li>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      <li>
        <Link to="/about/" activeClassName="active">
          About Us
        </Link>
      </li>
      <li>
        <Link to="/services/" activeClassName="active">
          Services
        </Link>
      </li>
      <li>
        <Link to="/gallery/" activeClassName="active">
          Gallery
        </Link>
      </li>
      <li>
        <Link to="/projects/" activeClassName="active">
          Projects
        </Link>
      </li>
      <li>
        <Link to="/blog/" activeClassName="active">
          Blog
        </Link>
      </li>
      <li>
        <Link to="/contact/" activeClassName="active">
          Contact Us
        </Link>
      </li>
    </>
  )
}

export default NavigationLinks
